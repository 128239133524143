import React, {useEffect, useState} from 'react';
import {Button, Card, Checkbox, CheckboxProps, Col, Divider, Input, message, Row, Space} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';

type unit = {
    unit: string
    count: number
}

type RatingType = {
    target: unit;
    rating: number;
}
interface ModalType {
    unitDatas: RatingType[],
    hide: any
}

type CheckboxType = { value: string, label: string }


const MergeUnitGroupConfirm: React.FC<ModalType> = (props) => {

    const [unitDatas, setUnitDatas] = useState<RatingType[]>([]);
    const [units, setUnits] = useState<string[]>([]);
    const [newName, setNewName] = useState<String>("");
    const [checkedList, setCheckedList] = useState<string[]>([]);


    useEffect(
        function () {
            setUnitDatas(props.unitDatas);
            let units: string[] = [];
            props.unitDatas.forEach((item) => {
                units.push(item.target.unit)
            })
            setUnits(units);
        },
        []
    );

    const submit = () => {

        const params = {newName: newName, unitDatas: checkedList};
        if (!newName) {
            message.error("请填写合并后的单位名称")
            return
        }
        create(params);
    };

    const create = (values: any) => {
        Fetch.postJSON(API.exam.exam_statistics.do_update_statistics_unit, values).then(() => {
            message.success('提交成功');
            cancel();
        });
    }

    const cancel = () => {

        props.hide();
    }

    const inputChange = (e: any) => {
        setNewName(e.target.value)
    }

    const remove = (index: number) => {

        const newItems = [...unitDatas];
        newItems.splice(index, 1);
        setUnitDatas(newItems);
    }

    const onCheckAllChange: CheckboxProps['onChange'] = (e) => {

        console.log(units)
        setCheckedList(e.target.checked ? units : []);
    };
    const onChange = (list: string[]) => {
        setCheckedList(list);
    };
    const checkAll = units.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < units.length;
    return (
        <>
            <div
                className="site-layout-background"
                style={{minHeight: 720}}
            >
                <div>
                    <Card title="" style={{minHeight: '750px'}} size="small">
                        <Space direction="vertical" style={{width: '100%', padding: '5px'}}>
                            <Input placeholder="单位名称" onChange={inputChange}/>
                            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange}
                                      style={{width: '100%', padding: '5px'}}
                                      checked={checkAll}>
                                全选
                            </Checkbox>
                            <Divider/>
                            <div style={{minHeight: '700px'}}>
                                <Checkbox.Group style={{width: '100%', padding: '5px'}}
                                                onChange={onChange} value={checkedList}>
                                    <Row gutter={[16, 16]}>
                                        {unitDatas.map(item =>
                                            <Col span={8}>
                                                <Checkbox
                                                    value={item.target.unit}>{item.target.unit} |
                                                    参与人数：{item.target.count}人</Checkbox>
                                            </Col>
                                        )}
                                    </Row>
                                </Checkbox.Group>
                            </div>
                            <Space>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    onClick={submit}
                                >
                                    保存
                                </Button>
                                <Button
                                    size="large"
                                    style={{marginRight: '15px'}}
                                    onClick={cancel}
                                >
                                    取 消
                                </Button>
                            </Space>
                        </Space>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default MergeUnitGroupConfirm;
