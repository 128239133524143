import React from 'react';
import {Button, DatePicker, message, Modal, Select, Space, Spin, Table, Tooltip} from 'antd';
import API from '../../../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../../../App';
import encryptData from '../../../common/crypto';
import {InfoCircleOutlined} from '@ant-design/icons';
import Fetch from "../../../common/FetchIt";
import styles from "../../hospitalauditv2/ServiceUserV2.module.css";
import {addParams, billSelectStatus, billStatus, NUMBER_STR, STRING_STR} from "../page/ListCommon";
import {BillHospitalList} from "../page/PageTypeTypes";
import {billHospitalListData} from "../page/PageCommon";
import AierHospitalSelectPage from "../../util/AierHospitalSelectPage";
import BreadcrumbInfo from "../../../common/breadcrumb/BreadcrumbInfo";
import dayjs from "dayjs";

type User = {};
const {confirm} = Modal;

class HospitalBillListClass extends AierHospitalSelectPage {

    state: BillHospitalList = {
        ...billHospitalListData,
    };

    componentDidMount() {

        super.componentDidMount();
        billSelectStatus([101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111], this);
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        let url = `${API.service_user_bill.hos_query_page}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("billDay", searchData.billDay, STRING_STR);
        url = url + addParams("hospitalId", searchData.hospitalId, NUMBER_STR);
        url = url + addParams("provinceId", searchData.provinceId, NUMBER_STR);
        url = url + addParams("status", searchData.status, NUMBER_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        })
    };


    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                window.location.reload()
            }
        );
    }


    changeCondition = (condition: any) => {
        if (condition.billDay) {
            condition.billDay = condition.billDay.format('YYYY-MM')
        }
        super.changeCondition(condition)
    };

    reset = () => {
        super.reset(billHospitalListData.searchData);
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    updateMaterialToBeReviewed = (billId: number) => {
        const that = this;
        confirm({
            title: `是否提交账单到基金会审核？`,
            okText: `提交`,
            onOk() {
                Fetch.putJSON(API.service_user_bill.update_material_to_be_reviewed, {billId: billId}).then(() => {
                    message.success('操作成功');
                    that.loadData();
                });
            },
        });
    }

    updateMaterialQuash = (billId: number) => {
        const that = this;
        confirm({
            title: `是否撤销申请？`,
            okText: `撤销`,
            onOk() {
                Fetch.putJSON(API.service_user_bill.update_material_quash, {billId: billId}).then(() => {
                    message.success('操作成功');
                    that.loadData();
                });
            },
        });
    }

    render() {

        let that = this;
        const {
            pageSize, totalElements,
            content, btnQ,
            billShowStatusArr, searchData
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '医院名称',
                dataIndex: 'hospitalName',
                align: `center`,
                width: 80,
                render(value) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '编码',
                dataIndex: 'code',
                align: `center`,
                width: 90,
                render: (text) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '账单月',
                dataIndex: 'billMonth',
                align: `center`,
                width: 30,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '人数',
                dataIndex: 'numUser',
                align: `center`,
                width: 30,
                render(value, record, index) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '账单金额',
                dataIndex: 'totalSubsidy',
                align: `center`,
                width: 50,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '状态',
                dataIndex: 'billStatus',
                align: `center`,
                width: 60,
                render(value, record: any) {
                    return <>
                        <div className={styles.statusDiv}>
                            <div
                                style={{
                                    wordBreak: 'break-all',
                                }}>{billStatus[value]}</div>
                            {(value === 106 || value === 111) ? (
                                <>
                                    <Tooltip placement="top" title={record.msg ? record.msg : "未填写原因"}>
                                        <InfoCircleOutlined className={styles.warnIcon}/>
                                    </Tooltip>
                                </>
                            ) : ''}
                        </div>
                    </>
                },
            },
            {
                title: '创建时间',
                dataIndex: 'createdDate',
                align: `center`,
                width: 100,
                render(value) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                width: 120,
                render: (text, record: any) => {
                    return (
                        <Space>
                            {/*TODO 权限*/}
                            {(record.billStatus === 108 || record.billStatus === 111) && (
                                // /serviceUserBill/receipt
                                <Link to={`/serviceUserBill/receipt?billId=${encryptData(record.id + "")}`}>
                                    上传收据
                                </Link>
                            )}
                            {(record.billStatus === 102 || record.billStatus === 106 ||
                                record.billStatus === 104) && (
                                <Button type="link" danger size={"small"}
                                        onClick={() => this.updateMaterialToBeReviewed(record.id)}>
                                    提交审核
                                </Button>
                            )}
                            {record.billStatus === 103 && (
                                <Button type="link" danger size={"small"}
                                        onClick={() => this.updateMaterialQuash(record.id)}>
                                    撤销
                                </Button>
                            )}
                            {(record.billStatus === 101 || record.billStatus == 102 || record.billStatus == 106
                                || record.billStatus == 104) && (
                                <Link to={`/serviceUserBill/materials?billId=${encryptData(record.id + "")}`}>
                                    上传盖章材料
                                </Link>
                            )}

                            <Link to={`/serviceUserBill/detail?billId=${encryptData(record.id + "")}`}>
                                详情
                            </Link>
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                    {/*    <Breadcrumb.Item>月度账单</Breadcrumb.Item>*/}
                    {/*</Breadcrumb>*/}
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space onKeyDown={this.handleKeyDown}>
                            {sessionStorage.orgName === '管理中心' && (
                                <>
                                    <Select
                                        showSearch
                                        placeholder="选择省份"
                                        optionFilterProp="children"
                                        onChange={(e) => {
                                            this.changeCondition({page: 0, provinceId: e})
                                        }}
                                        value={searchData.provinceId}
                                        filterOption={(input: any, option: any) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{width: 120}}
                                        options={this.state.zoneSelect}
                                    />
                                    <Select
                                        showSearch
                                        placeholder="选择执行机构名称"
                                        optionFilterProp="children"
                                        onChange={(e) => {
                                            this.changeCondition({page: 0, hospitalId: e})
                                        }}
                                        value={searchData.hospitalId}
                                        filterOption={(input: any, option: any) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{width: 200}}
                                        options={this.state.hospitalSelect}
                                    />
                                </>
                            )}
                            <DatePicker
                                onChange={(e) => {
                                    this.changeCondition({page: 0, billDay: e})
                                }}
                                picker="month"
                                value={searchData.billDay ? dayjs(searchData.billDay) : undefined}
                            />
                            <Select
                                style={{width: 220}}
                                placeholder="状态"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                value={searchData.status}
                                options={billShowStatusArr}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, status: e})
                                }}
                            />
                            <Button onClick={this.loadData}>
                                搜索
                            </Button>
                            <Button onClick={() => this.reset()}>重置</Button>
                        </Space>
                        <Table
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
            </>
        );
    }

}

export default function HospitalBillList() {
    return (

        <App selectedKeys={
            ['HospitalBillList']
        }

             openKeys="aierOphthalmology">
            <HospitalBillListClass/>
        </App>
    );
}
