import React, {Component} from 'react';
import {Button, Card, Divider, Space, Spin, Table,} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import encryptData, {decryptData} from "../../common/crypto";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import {ColumnsType} from "antd/es/table";
import {SubSource} from "../ExamCommonData";
import {Link} from "react-router-dom";

type User = {}

class ExamAnswerUserDetailClass extends Component<{}> {

    state = {
        id: decryptData(common.getQuery('id')),
        userListVos: [],
        answerListVos: [],
        importLoading: true
    };

    componentDidMount() {
        this.getData()
    };

    getData() {

        const {id} = this.state;
        let url = `${API.exam.answer.find_detail}?phoneId=${id}`;
        Fetch.get(url).then((data) => {
            this.setState({
                ...data,
                importLoading: false
            })
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }

    render() {
        const {userListVos, answerListVos} = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '姓名',
                dataIndex: 'userName',
                align: `center`,
            },
            {
                title: '手机号',
                dataIndex: 'userPhone',
                align: `center`,
            },

            {
                title: '参与组',
                align: `center`,
                dataIndex: 'userType',
            },
            {
                title: '参与平台',
                align: `center`,
                dataIndex: 'subSource',
                render: (text: any, record: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{SubSource[text]}</span>
                        </div>
                    );
                },
            },
            {
                title: '单位',
                align: `center`,
                dataIndex: 'unit',
            }
        ];
        const answerList: ColumnsType<User> = [

            {
                title: '答题码',
                dataIndex: 'userAnswerCode',
                align: `center`,
            },
            {
                title: '答题时间',
                dataIndex: 'answerDate',
                align: `center`,
            },
            {
                title: '分数',
                align: `center`,
                dataIndex: 'answerScore',
            },
            {
                title: '答题数量',
                align: `center`,
                dataIndex: 'quesCount',
            },
            {
                title: '答对题数',
                align: `center`,
                dataIndex: 'correctCount',
            }, {
                title: '答错题数',
                align: `center`,
                dataIndex: 'errorCount',
            }, {
                title: '用时',
                align: `center`,
                dataIndex: 'useTime',
            }, {
                title: '答题平台',
                align: `center`,
                dataIndex: 'subSource',
                render: (text: any, record: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{SubSource[text]}</span>
                        </div>
                    );
                },
            }, {
                title: '查看详情',
                align: `center`,
                dataIndex: 'subSource',
                render: (text: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>
                            <Link to={`/exam/user_answer/detail?id=${encryptData(record.id + "")}`}>
                                查看详情
                            </Link>
                        </Space>
                    );
                },
            }
        ];
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Spin tip={'加载中'} spinning={this.state.importLoading}>
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            <Card title={"参与记录"} size={"small"}>
                                <Table dataSource={userListVos} columns={columns} pagination={false}/>
                            </Card>
                            <Card title={"答题记录"} size={"small"}>
                                <Table dataSource={answerListVos} columns={answerList} pagination={false}/>
                            </Card>
                            <Card>
                                <Space>
                                    <Button
                                        // type="primary"
                                        size="large"
                                        onClick={this.cancel}
                                    >
                                        返回
                                    </Button>
                                </Space>
                            </Card>
                        </Space>
                    </Spin>
                </div>
            </div>
        );
    }
}

export default function ExamAnswerUserDetail() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <ExamAnswerUserDetailClass/>
        </App>
    );
}
