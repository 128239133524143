import React from 'react';
import {Button, Input, message, Popover, Select, Space, Spin, Table, Tooltip} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import {PageData} from '../util/types';
import styles from "./ServiceUser.module.css";
import * as XLSX from 'xlsx';
import {genderArr, listDefaultData} from "../util/AierCommon";
import {statusSelectArrV2} from "../util/AierCommonStatusV2";
import createModal from "../../common/createModal";
import ImportResultModal from '../../common/modal/ImportResultModal'
import DeletedServiceUserModal from './DeletedServiceUserModal'
import encryptData from '../../common/crypto';
import common from "../../common/common";
import BaseListPage from "../../common/listpage/BaseListPage";
import {DatabaseOutlined, InfoCircleOutlined} from '@ant-design/icons';
import RecordOperationsDetail from "../detail/RecordOperationsDetail";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import TransferModal from "./TransferModal";
import AuditModal from "./AuditModal";


type User = {};
const ImportResult = createModal(ImportResultModal);
const ImportDeletedServiceUserModal = createModal(DeletedServiceUserModal);
const ImportTransferModal = createModal(TransferModal);
const ImportAuditModal = createModal(AuditModal);

class ServiceUserAddListClass extends BaseListPage {

    state: PageData = {
        ...listDefaultData,
        btnQ: {
            ROLE_AIER_M_ADD: 0,
            ROLE_AIER_M_UPDATE: 0,
            ROLE_AIER_M_DETAILS: 0,
            ROLE_AIER_M_AUDIT: 0,
            ROLE_AIER_H_EXPORT_DATA: 0,
            ROLE_AIER_H_IMPORT_DATA: 0,
            ROLE_AIER_QR_CODE: 0,
            ROLE_DELETED_USER: 0,
            ROLE_80001: 0
        },
        modalTitle: '导入结果'
    };
    private importResult: any = null;
    private importDeletedServiceUserModal: any = null;
    private importTransferModal: any = null;
    private importAuditModal: any = null;
    private fileInput = document.getElementById('fileInput');

    componentDidMount() {

        super.componentDidMount();
        this.loadSelectData();
        this.loadHospital(null);
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        const hospitalId = searchData.hospitalId ? searchData.hospitalId : 0;
        const provinceId = searchData.provinceId ? searchData.provinceId : 0;
        let url = `${API.cooperateServiceUsers.add_list}?page=${searchData.page}&size=${pageSize}&name=${searchData.name}&phone=${searchData.phone}&idCard=${searchData.idCard}&status=${searchData.status}`;
        url = url + `&hospitalId=${hospitalId}&provinceId=${provinceId}`
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        })
    };


    createQrCode = () => {
        this.setState({
            importLoading: true,
            tip: "二维码生成中"
        });
        Fetch.get(
            `${API.cooperateServiceUsers.create_qr_code}`
        ).then((data) => {
            this.setState({
                importLoading: false,
            });
            window.open(API.domain + data)
        })
    };

    clickFileInput = () => {
        // @ts-ignore
        document.getElementById('fileInput').click()
    }

    provinceDownloadExcel = (e: any) => {
        this.setState({
            importLoading: true,
            tip: "数据导出中"
        });
        Fetch.get(
            `${API.cooperateServiceUsers.province_download_excel}`
        ).then((data) => {
            if (data) {
                const currentTimeStamp = Math.floor(Date.now() / 1000);
                common.downloadFile("“情暖老兵·守护光明”待审核名单" + currentTimeStamp + ".xlsx", API.domain + data, (callback: any) => {
                    if (callback === true) {
                        message.success('处理完成');
                    } else {
                        message.error('下载失败，请联系管理员');
                    }
                    this.setState({
                        importLoading: false,
                    });
                });
            } else {
                message.error('下载失败，请联系管理员');
                this.setState({
                    importLoading: false,
                });
            }
        });
    };

    onImportExcel = (file: any) => {
        this.setState({
            importLoading: true,
            tip: "数据导入中"
        });
        // 获取上传的文件对象
        const {files} = file.target;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                // @ts-ignore
                const {result} = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, {type: 'binary'});
                let data: any = []; // 存储获取到的数据
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        break; // 如果只取第一张表，就取消注释这行
                    }
                }
                const param = this.buildData(data);
                Fetch.postJSON(
                    `${API.cooperateServiceUsers.file_import_audit}`, param
                ).then((data) => {
                        const currentTimeStamp = Math.floor(Date.now() / 1000);
                        data.fileName = "“情暖老兵·守护光明”导入失败名单" + currentTimeStamp + ".xlsx";
                        this.setState(
                            {
                                importResultData: data
                            },
                            this.importResult.show
                        );
                    }
                );
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                this.setState({
                    importLoading: false,
                });
                return;
            } finally {

            }
        };
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(files[0]);
        //TODO 处理完成后刷新页面
    }

    buildData = (dates: any) => {

        const result = [];
        for (let i = 0; i < dates.length; i++) {
            const item = dates[i];
            result.push({
                name: item.姓名,
                idCard: item.身份证号,
                resideCity: item.居住城市,
                warVeteran: item.是否是老兵本人,
                warVeteranName: item.老兵姓名,
                warVeteranIdNumber: item.老兵身份证号,
                veteranRelations: item.申请人与老兵关系,
                veteran: item.校验结果,
            })
        }
        return result;
    }

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                this.loadData();
            }
        );
    }

    onClear = (e: any) => {
    }

    loadSelectData = () => {
        let url = `${API.zone.find_zone_by_pid}?parentId=0&r=1`;
        Fetch.get(url).then((data) => {

            let resultList = [];
            for (let dataKey in data) {
                resultList.push({
                    value: data[dataKey].id,
                    label: data[dataKey].name
                })
            }
            this.setState({
                zoneSelect: resultList
            });
        });

    };

    loadHospital(provId: any) {
        if (provId === undefined) {
            provId = null
        }
        this.state.searchData.hospitalId = undefined
        Fetch.get(`${API.hospital_area.find_select_data}?provId=${provId}`).then((data) => {
            this.setState({
                hospitalSelect: data
            });
        });
    };

    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
        if (condition.provinceId) {
            this.loadHospital(condition.provinceId)
        }
    };

    reset = () => {
        super.reset(listDefaultData.searchData);
        this.loadHospital(null);
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    openDeletedModal = (e: any) => {

        const that = this;
        let modalTitle = "您正在删除“" + e.name + "”的申请信息"
        this.setState({modalTitle: modalTitle, selectId: e.id}, () => {
            that.importDeletedServiceUserModal.show()
        })
    }

    openTransferModal = (e: any) => {

        const that = this;
        this.setState({selectId: e.id}, () => {
            that.importTransferModal.show()
        })
    }

    openAuditModal = (e: any) => {

        const that = this;
        let modalTitle = "您正在修改“" + e.name + "”的审核状态"
        this.setState({modalTitle: modalTitle, selectId: e.id}, () => {
            that.importAuditModal.show()
        })
    }

    render() {

        const {
            pageSize, totalElements,
            content, statusArr, btnQ, modalTitle,
            statusColorArr, searchData
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '姓名',
                dataIndex: 'name',
                align: `center`,
                // width: 50,
                render: (text) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '性别',
                dataIndex: 'gender',
                align: `center`,
                // width: 30,
                render(value, record, index) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{genderArr[value]}</span>
                    );
                },
            },
            {
                title: '年龄',
                dataIndex: 'age',
                align: `center`,
                // width: 30,
                render(value, record, index) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '联系电话',
                dataIndex: 'phone',
                align: `center`,
                // width: 70,
                render(value, record, index) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '身份证号码',
                dataIndex: 'idCard',
                align: `center`,
                // width: 100,
                render(value, record, index) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '收款方式',
                dataIndex: 'paymentMethod',
                align: `center`,
                // width: 80,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '关联医院',
                dataIndex: 'hospitalName',
                align: `center`,
                // width: 100,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '状态',
                dataIndex: 'status',
                align: `center`,
                // width: 100,
                render(value, record: any) {
                    return <>
                        <div className={styles.statusDiv}>
                            <div
                                style={{
                                    wordBreak: 'break-all',
                                    color: statusColorArr[value]
                                }}>{statusArr[value]}</div>
                            <Popover placement="left" title={record.name + "的操作记录"}
                                     content={<RecordOperationsDetail serviceId={record.id}/>}>
                                <DatabaseOutlined className={styles.recordIcon}/>
                            </Popover>
                            {value === 210 || value === 214 ? (
                                <>
                                    <Tooltip placement="top" title={record.msg ? record.msg : "未填写原因"}>
                                        <InfoCircleOutlined className={styles.warnIcon}/>
                                    </Tooltip>
                                </>
                            ) : ''}
                        </div>
                    </>;
                },
            },
            {
                title: '创建时间',
                dataIndex: 'createdDate',
                align: `center`,
                // width: 100,
                render(value) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                // width: 120,
                render: (text, record: any) => {
                    return (
                        <Space>
                            {(record.status === 1 ||
                                record.status === 202 ||
                                record.status === 203 ||
                                record.status === 218) && btnQ.ROLE_DELETED_USER ? (
                                <Button className={styles.antBtnPadding} type="link" danger size={"small"}
                                        onClick={() => this.openDeletedModal(record)}>
                                    删除
                                </Button>
                            ) : ('')}
                            {btnQ.ROLE_80001 && (record.status === 1 ||
                                record.status === 202 ||
                                record.status === 204 ||
                                record.status === 218) && (
                                <Button className={styles.antBtnPadding} type="link" danger size={"small"}
                                        onClick={() => this.openTransferModal(record)}>
                                    转院
                                </Button>
                            )}
                            {btnQ.ROLE_AIER_H_IMPORT_DATA && (record.status === 218) && (
                                <Button className={styles.antBtnPadding} type="link" size={"small"}
                                        onClick={() => this.openAuditModal(record)}>
                                    审核
                                </Button>
                            )}
                            {btnQ.ROLE_AIER_M_DETAILS ? (
                                <Link to={`/serviceUserDetail/detail?id=${encryptData(record.id + "")}`}>
                                    详情
                                </Link>
                            ) : ('')}
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        {/* status: '', search: '', type: '', */}

                        <Space onKeyDown={this.handleKeyDown}>
                            <Input
                                placeholder="姓名"
                                value={searchData.name}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, name: e.target.value})
                                }
                                }
                            />
                            <Input
                                placeholder="手机号"
                                value={searchData.phone}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, phone: e.target.value})
                                }
                                }
                            />
                            <Input
                                placeholder="身份证号"
                                value={searchData.idCard}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, idCard: e.target.value})
                                }
                                }
                            />
                            <Select
                                style={{width: 220}}
                                placeholder="状态"
                                optionFilterProp="children"
                                options={statusSelectArrV2}
                                value={searchData.status}
                                allowClear={true}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, status: e})
                                }}
                            />

                            <Button onClick={this.loadData}>
                                搜索
                            </Button>
                            <Button onClick={() => this.reset()}>重置</Button>
                            {btnQ.ROLE_AIER_QR_CODE ? (
                                <Button onClick={this.createQrCode}>生成二维码</Button>
                            ) : ('')}

                        </Space>
                        <Space className={styles.optMenu}>
                            {btnQ.ROLE_AIER_H_EXPORT_DATA ? (
                                <Button
                                    onClick={this.provinceDownloadExcel}
                                    className={styles.addBtn}
                                >
                                    导出待审核数据
                                </Button>
                            ) : ('')}
                            {btnQ.ROLE_AIER_H_IMPORT_DATA ? (
                                <>
                                    <Button
                                        onClick={this.clickFileInput}
                                    >
                                        导入已验真数据
                                    </Button>
                                </>
                            ) : ('')}
                        </Space>
                        {sessionStorage.orgName === '管理中心' ? (
                            <Space className={styles.areaHospitalSelect}>
                                <Select
                                    showSearch
                                    placeholder="选择省份"
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, provinceId: e})
                                    }}
                                    value={searchData.provinceId}
                                    filterOption={(input: any, option: any) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{width: 120}}
                                    options={this.state.zoneSelect}
                                />
                                <Select
                                    showSearch
                                    placeholder="选择执行机构名称"
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, hospitalId: e})
                                    }}
                                    value={searchData.hospitalId}
                                    filterOption={(input: any, option: any) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{width: 200}}
                                    options={this.state.hospitalSelect}
                                />
                            </Space>
                        ) : ('')}
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
                <ImportResult
                    width={400}
                    title={"导入结果"}
                    importResult={this.state.importResultData}
                    onCancel={this.closeModal}
                    ref={(c) => (this.importResult = c)}
                />
                <ImportDeletedServiceUserModal
                    width={500}
                    title={modalTitle}
                    importResult={this.state.selectId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.importDeletedServiceUserModal = c)}
                />
                <ImportTransferModal
                    width={500}
                    title={"转院"}
                    serviceId={this.state.selectId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.importTransferModal = c)}
                />
                <ImportAuditModal
                    width={500}
                    title={modalTitle}
                    serviceId={this.state.selectId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.importAuditModal = c)}
                />
                <input id="fileInput" className={styles.fileUploader} type='file'
                       accept='.xlsx, .xls'
                       onChange={this.onImportExcel}/>
            </>
        );
    }

}

export default function ServiceUserAddList() {

    return (

        <App selectedKeys={
            ['ServiceUserAdd']
        }

             openKeys="aierOphthalmology">
            <ServiceUserAddListClass/>
        </App>
    )
        ;
}
