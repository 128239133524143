const PATH = {
    login: '/',
    index: '/index',
    wxLogin: '/wxLogin',
    hospital_applets_config: {
        list: '/hospital_applets_config/list'
    },
    aier_hospital: {
        // edit_aier_hospital: '/aier_hospital/edit',
        list: '/aier_hospital/list'
    },
    registered: {
        doRegistered: '/register',
        registerSuccess: '/register/success',
    },
    account: {
        list: '/account',
    },
    demo: {
        list: '/demolist',
        detail: '/demoDetail',
    },
    invoice: {
        list: '/invoicelist',
        detail: '/invoicedetail',
    },
    alt: {
        category: '/category',
        atlproject: '/atlproject',
        atltemplate: '/atltemplate',
        templatedetail: '/templatedetail',
        selectTemplate: '/selectTemplate',
        templatedetails: '/templatedetails',
    },
    activate: '/activate',
    project: {
        list: '/project',
        detail: '/project/detail',
        review: '/project/review',
        edit: '/project/edit',
    },
    volunteer: {
        list: '/volunteer',
        verify: '/volunteerverify',
        detail: '/volunteerDetail',
    },

    yueling: {
        list: '/yueling',
        detail: '/yueling',
    },
    institution: {
        index: '/institution',
        list: '/institution',
        institutionPermissions: "/institutionPermissions",
        reviewedList: '/institution/reviewed_list',
        reviewedDetail: '/institution/reviewed_detail'
    },
    gongyiActivity: {
        list: '/gongyiActivity',
        review: '/gongyiActivity/review',
        detail: '/gongyiActivity',
        recruit: '/gongyiActivityRecruit',
    },
    donationActivity: {
        list: '/donationActivity',
        review: '/donationActivity/review',
        detail: '/donationActivity/detail',
    },
    ad: {
        list: '/ad',
    },
    user: {
        info: '/user/info',
        institution: '/user/institution',
        list: '/user',
        qualification: '/user/qualification',
        detail: '/user/detail',
        password: '/user/password',
        limits: '/userlimits',
        limitsedit: '/userlimitsedit',
    },
    message: {
        list: '/message',
    },
    goods: {
        supplier: '/supplier',
        list: '/goodslist',
        edit: '/goodsedit',
        mall: '/goodsmall',
        view: '/goodsview',
        orderlist: '/goodsorderlist',
        orderdetail: '/orderdetail',
    },
    operationalActivity: {
        operationalActivityList: '/operationalActivity/list',
        operationalActivityDetail: '/operationalActivity/detail',
        operationalActivityEdit: '/operationalActivity/edit',
    },
    cooperateServiceUsers: {
        cooperateServiceUsersAddList: '/cooperateServiceUsers/addList',
        // cooperateServiceUsersAddEdit: '/cooperateServiceUsers/edit',
        serviceUserDetail: '/serviceUserDetail/detail',
        // serviceUserFinallyAudit: '/serviceUserDetail/finally_audit',
        // serviceUserPatientAudit: '/serviceUserDetail/patient_audit',
    },
    // cooperateServiceUsersHospitalAudit: {
    //     hospitalAuditList: '/hospitalAuditList/auditList',
    //     hospitalAuditScreening: '/hospitalAuditList/screening',
    //     hospitalAuditPostoperativeInfo: '/hospitalAuditList/postoperativeInfo',
    // },
    cooperateServiceUsersFinancialAudit: {
        financialAuditList: '/financialAuditList/auditList',
        financialAuditAudit: '/financialAuditDetail/audit',
        financialBatchVoucher: '/financial/batch_voucher',
    },
    cooperateServiceUsersRoster: {
        serviceUserRosterList: '/serviceUserRoster/list',
    },
    monthlyStatistics: {
        monthlyStatisticsList: '/monthlyStatistics/list'
    },
    // fileView: {
    //     view: '/file_view/view'
    // },
    cooperateServiceUsersHospitalV2: {
        hospitalAuditV2List: '/hospitalAuditListV2/auditList',
        hospitalAuditV2Screening: '/hospitalAuditListV2/screening',
        hospitalAuditV2PostoperativeInfo: '/hospitalAuditListV2/postoperativeInfo',
        refine: '/hospitalAuditListV2/refine',
        editPatientAccount: '/hospitalAuditListV2/editPatientAccount'
    },
    reimbursedBatch: {
        batchList: '/reimbursedBatch/list',
        detailBatchList: '/reimbursedBatchDetail/list'
    },
    hospitalStats: {
        hospitalStatsIndex: '/hospitalStats/index'
    },
    hospitalAccount: {
        hospitalAccountEdit: '/hospitalAccount/edit'
    },
    service_user_bill: {
        hos_query_page: '/serviceUserBill/hos_query_page',
        detail: '/serviceUserBill/detail',
        materials: '/serviceUserBill/materials',
        financial_bill_list: '/serviceUserBill/financial_bill_list',
        financial_bill_audit: '/serviceUserBill/financial_bill_audit',
        receipt: '/serviceUserBill/receipt',
        audit_receipt: '/serviceUserBill/audit_receipt',
    },
    service_user_bill_batch: {
        list: '/service_user_bill_batch/list',
        detail_list: '/service_user_bill_batch/detail_list',
    },
    dhHospital: {
        dhHospitalList: '/dhHospital/dhHospitalList',
    },
    dhPatientApplication: {
        list: '/dh/patient_application/list',
        detail: '/dh/patient_application/detail',
        booking: '/dh/patient_application/booking',
        add: '/dh/patient_application/add',
        edit: '/dh/patient_application/edit'
    },
    dhHospitalPatient: {
        list: '/dh/hospital_patient/list',
        group_list: '/dh/hospital_patient/group_list',
        detail: '/dh/hospital_patient/detail',
        report: '/dh/hospital_patient/report',
        edit: '/dh/hospital_patient/edit',
        fee: '/dh/hospital_patient/fee',
    },
    dhDashboard: {
        hospital_dashboard: '/dh/hospital/hospital_dashboard'
    },
    dhSalesman: {
        list: '/dh/dh_salesman/list'
    },
    dhPhoneTask: {
        list: '/dh/phone_task/list',
        all_list: '/dh/phone_all_task/list',
        return_visit: '/dh/phone_task/return_visit',
        return_visit_detail: '/dh/phone_task/return_visit_detail',
        obligate_detail: '/dh/phone_task/obligate_detail',
        phone_task_detail: '/dh/phone_task/phone_task_detail',
    },
    dhFinance: {
        list: '/dh/finance/list',
        audit: '/dh/finance/audit',
    },
    reimbursed_batch: {
        list: '/dh/reimbursed_batch/list',
        detail_list: '/dh/reimbursed_batch/detail_list',
    },
    taxes: {
        list: '/taxes/list',
        detail: '/taxes/detail',
    },
    test: {
        testTime: '/test/time'
    },
    exam: {
        paper: {
            list: '/exam/paper/list'
        },
        ques: {
            list: '/exam/ques/list',
            add: '/exam/ques/add'
        },
        answerUser: {
            list: '/exam/answer_user/list',
            detail: '/exam/answer_user/detail'
        },
        userAnswer: {
            list: '/exam/user_answer/list',
            detail: '/exam/user_answer/detail',
        },
        examStatistics: {
            find_unit: '/exam/exam_statistics/statistics',
            merge_group: '/exam/exam_statistics/merge_group',
        }
    }

    // aierPublicizeVideo: {
    //     list: '/aierPublicizeVideo/list'
    // }
};
export default PATH;
