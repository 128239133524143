import React, {useEffect, useRef, useState} from 'react';
import Api from "../../common/api";
import API from "../../common/api";
import {Button, Card, Descriptions, Flex, Image as AntdImage, message, Modal, Space, Spin} from "antd";
import Fetch from "../../common/FetchIt";
import {CheckCircleOutlined} from '@ant-design/icons';

type ImageCropperType = {
    fullImage: string | null,
    serviceUserData: ServiceUserType[]
    releaseTime: string | null
}

type ServiceUserType = {
    name: string | undefined,
    price: string | undefined,
    backCard: string | undefined,
    serviceId: number | undefined,
    msg: string | undefined
}
type VoucherShowType = ServiceUserType & {
    img: any,
    confirmSer: boolean
}
const {confirm} = Modal;
const ImageCropperVoucher = (prop: ImageCropperType) => {

    const [croppedImages, setCroppedImages] = useState<VoucherShowType[]>([]);
    const [importLoading, setImportLoading] = useState<boolean | undefined>(false);
    const [tip, setTip] = useState<string | undefined>('');

    const canvasRef = useRef(null);
    // formRef = React.createRef<FormInstance>();

    useEffect(() => {

        if (prop.serviceUserData) {
            handleCropImages()
        } else {
            setCroppedImages([])
        }
    }, [prop.serviceUserData])

    const handleCropImages = () => {
        if (prop.fullImage == null) {
            return;
        }
        showSpin("图片截取中...")
        setCroppedImages([]);
        const fullImg = new Image();
        fullImg.src = Api.domain + prop.fullImage;
        fullImg.crossOrigin = "anonymous";
        let useHeight = 0;
        fullImg.onload = () => {
            const croppedImagesArray: VoucherShowType[] = [];
            const height = 105;
            prop.serviceUserData.forEach((item: ServiceUserType, index: number) => {
                const canvas = document.createElement('canvas');
                canvas.width = fullImg.width;
                canvas.height = fullImg.height;
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.drawImage(fullImg, 0, 0);
                    const croppedImageData = ctx.getImageData(0, useHeight, fullImg.width, height);
                    const croppedCanvas = document.createElement('canvas');
                    const croppedCtx = croppedCanvas.getContext('2d');
                    croppedCanvas.width = fullImg.width;
                    croppedCanvas.height = height;
                    if (croppedCtx) {
                        croppedCtx.putImageData(croppedImageData, 0, 0);
                    }
                    const img = croppedCanvas.toDataURL();
                    let voucherShow: VoucherShowType = {...item, img: item.serviceId ? img : '', confirmSer: false};
                    croppedImagesArray.push(voucherShow);
                    useHeight = useHeight + height;
                }
            });
            hideSpin()
            setCroppedImages(croppedImagesArray);
        };
    };

    const submit = (value: any) => {

        confirm({
            title: `是否确认提交？`,
            onOk() {
                showSpin("数据提交中...")
                const params = {
                    serviceId: value.serviceId,
                    releaseTime: prop.releaseTime,
                    file: value.img,
                    fileName: value.name
                }
                Fetch.postJSON(API.cooperateServiceUsersStatusV2.finance_batch_confirm, params).then((data) => {
                    croppedImages.forEach((item: VoucherShowType) => {
                        if (item.serviceId === value.serviceId) {
                            item.confirmSer = true;
                        }
                    })
                    setCroppedImages(croppedImages);
                    hideSpin()
                    message.success("操作成功！")
                }).catch(e => {
                    hideSpin()
                });
            }
        });
    }

    const showSpin = (tip: string) => {
        setImportLoading(true)
        setTip(tip);
    }

    const hideSpin = () => {
        setImportLoading(false)
        setTip('');
    }

    // @ts-ignore
    return (
        <div>
            <Spin tip={tip} spinning={importLoading}>
                <Flex gap="middle" key={"Flex"}>
                    <Space direction="vertical" style={{width: "50%"}}>
                    {croppedImages.map((item, index) => (

                        <Card key={index} style={{}} title={"序号：" + (index + 1)}>
                                <Descriptions>
                                    <Descriptions.Item label="收款人姓名">
                                        {item.name}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="银行卡号">
                                        {item.backCard}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="报销金额">
                                        {item.price}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="警告">
                                        {item.msg}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Flex gap="middle">
                                    <AntdImage src={item.img}/>
                                    {item.img && (
                                        <>
                                            {
                                                item.confirmSer ? (
                                                    <CheckCircleOutlined
                                                        style={{fontSize: 'xx-large', color: '#1677ff'}}/>
                                                ) : (
                                                    <Button type={"primary"}
                                                            onClick={() => submit(item)}
                                                    >完成报销</Button>
                                                )
                                            }
                                        </>
                                    )}
                                </Flex>
                            </Card>

                    ))}
                </Space>
                    {prop.fullImage &&
                        (
                            <AntdImage style={{height: 'auto'}} src={Api.domain + prop.fullImage}/>
                        )}
                </Flex>
                <canvas ref={canvasRef} style={{display: 'none'}}/>
            </Spin>
        </div>
    );
};

export default ImageCropperVoucher;
