import React from 'react';
import {Button, DatePicker, Divider, Input, Modal, Popover, Select, Space, Spin, Table, Tooltip} from 'antd';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import encryptData from '../../common/crypto';
import BaseListPage from "../../common/listpage/BaseListPage";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {HospitalPatientData, HospitalPatientType} from "../common/DhPageCommon";
import {genderArr} from "../../cooperate/util/AierCommon";
import {BookingStatus, BookingTime, dhStatusArray, dhStatusColorObj, dhStatusObj} from "../common/DhDictData";
import styles from "../../cooperate/hospitalauditv2/ServiceUserV2.module.css";
import {DatabaseOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {addParams, NUMBER_STR, STRING_STR} from "../../cooperate/bill/page/ListCommon";
import DhRecordOperationsDetail from "../patientDetail/DhRecordOperationsDetail";

type User = { name: string | '', msg: string | '', id: number | 0 };
const {confirm} = Modal;
const defGroupData: HospitalPatientType = {
    ...HospitalPatientData,
    searchData: {
        name: undefined,
        phone: undefined,
        idCard: undefined,
        status: undefined,
        bookingDate: undefined,
        page: 0
    },
}

class DhHospitalGroupPatientListClass extends BaseListPage {

    state: HospitalPatientType = {
        ...defGroupData,
        btnQ: {}
    };
    private DHospitalBookingModelResult: any = null;

    componentDidMount() {

        super.componentDidMount();
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        let url = `${API.dentistry.dh_service_user_booking.admin_group_list}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("name", searchData.name, STRING_STR);
        url = url + addParams("phone", searchData.phone, STRING_STR);
        url = url + addParams("idCard", searchData.idCard, STRING_STR);
        url = url + addParams("status", searchData.status, NUMBER_STR);
        url = url + addParams("bookingDate", searchData.bookingDate?.format('YYYY-MM-DD'), STRING_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };


    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        super.reset(defGroupData.searchData);
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    // signedByDoctor = (e: number) => {
    //
    //     let that = this;
    //     confirm({
    //         title: `是否代替患者签到？`,
    //         okText: `提交`,
    //         onOk() {
    //             Fetch.postJSON(API.dentistry.service_user.signed_by_doctor, {bookingId: e}).then(() => {
    //                 message.success('操作成功');
    //                 that.loadData();
    //             });
    //         },
    //     });
    // }
    // editBooking = (e: number) => {
    //
    //     this.setState({
    //         selectId: e
    //     }, () => {
    //         this.DHospitalBookingModelResult.show();
    //     })
    // }

    closeModal = () => {

        this.loadData();
    }

    // updateErProcessApply = (id: number) => {
    //     const that = this;
    //     confirm({
    //         title: `是否提交患者信息到基金会审核？`,
    //         okText: `提交`,
    //         onOk() {
    //             Fetch.putJSON(API.dentistry.service_user_status.er_process_apply, {bookingId: id}).then(() => {
    //                 message.success('操作成功');
    //                 that.loadData();
    //             });
    //         },
    //     });
    // }
    //
    // updateErProcessCompleteAccountSubmit = (id: number) => {
    //     const that = this;
    //     confirm({
    //         title: `是否提交患者信息到基金会？`,
    //         okText: `提交`,
    //         onOk() {
    //             Fetch.putJSON(API.dentistry.service_user_status.update_erp_fail_submit, {bookingId: id}).then(() => {
    //                 message.success('操作成功');
    //                 that.loadData();
    //             });
    //         },
    //     });
    // }
    //
    // updateErProcessUndo = (id: number) => {
    //     const that = this;
    //     confirm({
    //         title: `是否撤消申请？`,
    //         okText: `提交`,
    //         onOk() {
    //             Fetch.putJSON(API.dentistry.service_user_status.er_process_undo, {bookingId: id}).then(() => {
    //                 message.success('操作成功');
    //                 that.loadData();
    //             });
    //         },
    //     });
    // }

    render() {
        const {
            pageSize, totalElements,
            btnQ, searchData, content
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '姓名',
                dataIndex: 'name',
                align: `center`,
                render: (text, record: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{text}
                        </span>
                            {!record.accountRefine ? (
                                <Tooltip placement="top" title={"账户信息待完善"}>
                                    <InfoCircleOutlined className={styles.warnIcon}/>
                                </Tooltip>
                            ) : ('')}
                        </div>
                    );
                },
            },
            {
                title: '性别',
                dataIndex: 'gender',
                align: `center`,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{genderArr[value]}</span>
                    );
                },
            },
            {
                title: '年龄',
                dataIndex: 'age',
                align: `center`,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '联系电话',
                dataIndex: 'phone',
                align: `center`,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '身份证号码',
                dataIndex: 'idNumber',
                align: `center`,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '关联医院',
                dataIndex: 'hospitalName',
                align: `center`,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '患者状态',
                dataIndex: 'dhStatus',
                align: `center`,
                render(value, record) {
                    return <>
                        <div className={styles.statusDiv}>
                            <div
                                style={{
                                    wordBreak: 'break-all',
                                    color: dhStatusColorObj[value]
                                }}>{dhStatusObj[value]}
                            </div>
                            <Popover placement="left" title={record.name + "的操作记录"}
                                     content={<DhRecordOperationsDetail bookingId={record.id}/>}>
                                {value === 113 || value === 116 ? (
                                    <InfoCircleOutlined className={styles.warnIcon}/>
                                ) : (
                                    <DatabaseOutlined className={styles.recordIcon}/>
                                )}
                            </Popover>
                        </div>
                    </>;
                },
            },
            {
                title: '预约状态',
                dataIndex: 'bookingStatus',
                align: `center`,
                render(value) {
                    return <>
                        <div className={styles.statusDiv}>
                            <div
                                style={{
                                    wordBreak: 'break-all'
                                }}>{BookingStatus[value]}</div>
                        </div>
                    </>;
                },
            },
            {
                title: '预约时间',
                dataIndex: 'bookingDate',
                align: `center`,
                render(value) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '上午&下午',
                dataIndex: 'bookingTime',
                align: `center`,
                render(value) {
                    return <span style={{wordBreak: 'break-all'}}>{BookingTime[value]}</span>;
                },
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                render: (value, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>
                            {(record && (record.bookingStatus === 1 ||
                                record.bookingStatus === 2 ||
                                record.bookingStatus === 3)) && (
                                <>
                                    {/*{record.dhStatus === 104 && (*/}
                                    {/*    <>*/}
                                    {/*        {(record.bookingStatus === 1 && btnQ.ROLE_141001) && (*/}
                                    {/*            <Button className={styles.antBtnPadding} type="link" danger*/}
                                    {/*                    size={"small"}*/}
                                    {/*                    onClick={() => this.signedByDoctor(record.id)}>*/}
                                    {/*                签到*/}
                                    {/*            </Button>*/}
                                    {/*        )}*/}
                                    {/*        {(record.bookingStatus === 1 && btnQ.ROLE_141002) && (*/}
                                    {/*            <Button className={styles.antBtnPadding} type="link" danger*/}
                                    {/*                    size={"small"}*/}
                                    {/*                    onClick={() => this.editBooking(record.id)}>*/}
                                    {/*                改约*/}
                                    {/*            </Button>*/}
                                    {/*        )}*/}
                                    {/*    </>*/}
                                    {/*)}*/}
                                    {/*{(record.dhStatus === 117 && btnQ.ROLE_141009) && (*/}
                                    {/*    <Button className={styles.antBtnPadding} type="link" danger*/}
                                    {/*            size={"small"}*/}
                                    {/*            onClick={() => this.updateErProcessCompleteAccountSubmit(record.id)}>*/}
                                    {/*        提交申请*/}
                                    {/*    </Button>*/}
                                    {/*)}*/}
                                    {/*{(record.dhStatus === 109 || record.dhStatus === 111 || record.dhStatus === 113*/}
                                    {/*    && btnQ.ROLE_141003) && (*/}
                                    {/*    <Button className={styles.antBtnPadding} type="link" danger*/}
                                    {/*            size={"small"}*/}
                                    {/*            onClick={() => this.updateErProcessApply(record.id)}>*/}
                                    {/*        提交申请*/}
                                    {/*    </Button>*/}
                                    {/*)}*/}
                                    {/*{(record.dhStatus === 110 && btnQ.ROLE_141004) && (*/}
                                    {/*    <Button className={styles.antBtnPadding} type="link" danger*/}
                                    {/*            size={"small"}*/}
                                    {/*            onClick={() => this.updateErProcessUndo(record.id)}>*/}
                                    {/*        撤消*/}
                                    {/*    </Button>*/}
                                    {/*)}*/}
                                    {/*{(this.EditOpen.includes(record.dhStatus) && btnQ.ROLE_141005) && (*/}
                                    {/*    <Link to={`/dh/hospital_patient/edit?id=${encryptData(record.id + "")}`}>*/}
                                    {/*        编辑*/}
                                    {/*    </Link>*/}
                                    {/*)}*/}
                                    {/*{(record.dhStatus === 105 &&*/}
                                    {/*    btnQ.ROLE_141006 &&*/}
                                    {/*    (record.bookingStatus === 3 || record.bookingStatus === 2)) && (*/}
                                    {/*    <Link to={`/dh/hospital_patient/report?id=${encryptData(record.id + "")}`}>*/}
                                    {/*        上传义诊报告*/}
                                    {/*    </Link>*/}
                                    {/*)}*/}
                                    {/*{(record.dhStatus === 107 && btnQ.ROLE_141007) && (*/}
                                    {/*    <Link to={`/dh/hospital_patient/fee?id=${encryptData(record.id + "")}`}>*/}
                                    {/*        费用信息*/}
                                    {/*    </Link>*/}
                                    {/*)}*/}
                                    {(btnQ.ROLE_141008) && (
                                        <Link to={`/dh/hospital_patient/detail?id=${encryptData(record.id + "")}`}>
                                            详情
                                        </Link>
                                    )}
                                </>
                            )}
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space onKeyDown={this.handleKeyDown}>
                            <Input
                                placeholder="姓名"
                                value={searchData.name}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, name: e.target.value})
                                }
                                }
                            />
                            <Input
                                placeholder="手机号"
                                value={searchData.phone}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, phone: e.target.value})
                                }
                                }
                            />
                            <Input
                                placeholder="身份证号"
                                value={searchData.idCard}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, idCard: e.target.value})
                                }
                                }
                            />
                            <Select
                                style={{width: 220}}
                                placeholder="状态"
                                optionFilterProp="children"
                                options={dhStatusArray}
                                value={searchData.status}
                                allowClear={true}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, status: e})
                                }}
                            />
                            <DatePicker value={searchData.bookingDate}
                                        onChange={(e) => {
                                            this.changeCondition({page: 0, bookingDate: e})
                                        }}/>

                            <Button onClick={this.loadData}>
                                搜索
                            </Button>
                            <Button onClick={() => this.reset()}>重置</Button>

                        </Space>
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
                {/*<DHospitalBookingModel*/}
                {/*    width={1300}*/}
                {/*    title={'预约医院'}*/}
                {/*    importResult={selectId}*/}
                {/*    onCancel={this.closeModal}*/}
                {/*    ref={(c) => (this.DHospitalBookingModelResult = c)}*/}
                {/*/>*/}
            </>
        );
    }

}

export default function DhHospitalGroupPatientList() {

    return (

        <App selectedKeys={
            ['DH_H_GROUP_BOOKING']
        }
             openKeys="ORAL_GUARD">
            <DhHospitalGroupPatientListClass/>
        </App>
    )
        ;
}
