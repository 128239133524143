module.exports = {
    //local
    // pathDomain: 'http://127.0.0.1:8099',
    // cdnDomain: 'https://testcdn.lelingzhiyuan.com/',
    //sit
    pathDomain: 'https://test.lelingzhiyuan.com',
    cdnDomain: 'https://testcdn.lelingzhiyuan.com/',
    //prod
    // pathDomain: 'https://app.lelingzhiyuan.com',
    // cdnDomain: 'https://appcdn.lelingzhiyuan.com/',

    staticImg: 'https://appcdn.lelingzhiyuan.com/static'
}
